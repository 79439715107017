const config = {
    mui_license: '3a702a384fd8d364b1c0047b8ac04953Tz03MzUyNyxFPTE3MjQ2ODUzMjQwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
    oai: 'sk-proj-',
    k_experimental: 'MqqF3lU9yPKIY7az9GnuT3BlbkFJLKEFWIxxHTSZ9i14QF4t',
    requests_content_type: 'application/json',
    mock_server_api_url: "https://mock.nukleus.technology/",
    use_mock_data: false,
    mock_credentials: {user:'nukleus', password:'nukleus'},
    active_env: "dev",
    envs: {
        "dev":{ 
            domain: "https://dev.nukleus.technology/",
            client_secret: '0FBHhxSg6wn61yeFY9VlkTN1YvoRHfXX/jVOiWS0',
            qa_api_url: "https://qa-api.nukleus.technology/",
            modules: ['Suche', 'Projekte', 'Berichte', 'Akzeptanzkriterien', 'Berichtdefinitionen', 'Berichtergebnisse']
        },
        "stg":{ 
            domain: "https://stg.nukleus.technology/",
            client_secret: 'ffoiYFzRpsf9Oisxg7SPz6WBjamy8x8JR7xNzsNy',
            qa_api_url: "https://qa-api.nukleus.technology/",
            modules: ['Suche', 'Projekte', 'Berichte', 'Akzeptanzkriterien', 'Berichtdefinitionen', 'Berichtergebnisse']
        },
        "prod":{
            domain: "https://nukleus.technology/",
            client_secret: 'r/u3aV4bexEujluMZA4LA8HKfDR+0LGXr9iiC3Sj',
            qa_api_url: "https://qa-api.nukleus.technology/",
            modules: ['Suche', 'Projekte', 'Berichte', 'Akzeptanzkriterien', 'Berichtdefinitionen', 'Berichtergebnisse']
        }
    },
    nextcloud_path: "nextcloud/apps/files/?dir=/",
    api_path: "api/v1/",
    task_service: "tasks/",
    client_id: 'frontend',

    ui_settings: {
        drawer_width: 240,
        pagination_page_size_options: [5,10,15,25,50],
        pagination_page_size: 25,
        search_doc_top_k: 10,
        search_doc_inner_hits: 5,
        search_doc_result_padding:50,
        profile_options: [
            { id:'sigmoidal', name: 'Sigmoidal' },
            { id:'exponential', name: 'Exponential'},
            { id:'linear', name: 'Linear' },
            { id:'hyperbolic', name: 'Hyperbolic' }
        ]
    },

    ui_messages: {
        ai_disclaimer: "Dieser Inhalt wurde automatisch unter anwendung von künstlicher Intelligenz generiert.",
        goal_category: [
            "Neu_Erstellung",
            "Erhaltung_Erneuerung",
            "Revision_Veränderung",
            "Unterhalt_Aufrechterhaltung",
            "Nutzung_Betrieb",
            "Allgemein",
        ],
        usage_category: [
            "Areal",
            "Bildung",
            "Brücke",
            "Büro",
            "Fahrnissbauten",
            "Freiland",
            "Freizeitanlage",
            "Gastronomie",
            "Gewerbe",
            "Hotelerie",
            "Industrie",
            "Kaserne",
            "Komandoposten",
            "Kraftwerk",
            "Kunstbaute",
            "Mischnutzung",
            "Schiessanlage",
            "Schutzanlage",
            "Schutzraum",
            "Spital",
            "Trasse",
            "Tunnel",
            "Wasseraufbereitung",
            "Wohnen",
            "Lärmschutz",
            "Dokument",
            "Technologie",
            "Allgemein",
        ],
    },
    search_questions: {
        general: [
            "Ziele im Projekt und Protokoll?",
            "Ist das Budget zur Verfügung und gibt es einen Bericht zur Verwendung?",
            "Welche Risiken bestehen im aktuellen Verlauf und liegt ein Risiobericht vor?",
            "Sind die Projektgrundlagen Bewertet und gibt es eine Risikobericht?"    
        ],
        gs1: [
            "Wie funktioniert eine Umwandlung einer 20-stelligen Zeichenfolge in Breiten- und Längengrad?",
            "Was ist ein Barcode?",
            "Wo plaziere ich den Barcode am besten?",
            "Wissenswertes zum Standart ISO-IEC 646?",
            "Beispiele zur Umwandlung von Maßen und Gewichten für die Anwendung?",
            "Bestimmung des Jahrhunderts in Datumsangaben?",
            "Dienstleistungsbeziehungen (in beiden Sprachen)",
            "GMN Vergaberegeln",
            "GS1 Identifikationssystem",
            "GTIN-12 und RCN-12 in einem UPC-E Strichcode",
            "Zweidimensionale Codes – QR Code",
        ]
    },
    topicMenuItems: [
        "Bauleitung",
        "Baumanagement Immobilien",
        "Bautechnik",
        "Beschaffung",
        "Boden Altlasten",
        "Brandschutz",
        "Denkmalschutz",
        "Elektro, MSRL, EMP-Schutz",
        "Energie",
        "Erdbebensicherheit",
        "Finanzen",
        "Gebäudeautomatisation",
        "Gebäudeschadstoffe",
        "Gebäudetechnik (HLKKS)",
        "Hermes",
        "Immobilien Managment",
        "Lärm",
        "Naturschutz",
        "Normen",
        "Photovoltaikanlagen",
        "Projektabschluss",
        "Projektdokumentation",
        "Rechnung",
        "Recht",
        "Safety und Security",
        "Sanitäranlagen",
        "Schutzbau",
        "Störfall, WLGF",
        "Stromversorgung",
        "Telematik",
        "Umweltmanagement und Nachhaltigkeit",
        "Wasser"
    ],
    test_data: {
        client_categories: [
            "Projektinhalte",
            "Baukosten",
            "Bauzeit",
            "Verkehrssicherheit",
            "Arbeitssicherheit",
        ],
        types: [
            "Abwicklungsaspekt",
            "Wirkungsaspekt",
        ],
        groups: [
            "übergreifende Themen",
            "Wirtschaft",
            "Gesellschaft",
            "Umwelt",
        ],
        acceptance_criteria: [
            { 
                client_category:"Projektinhalte", type:"Wirkungsaspekt", group:"übergreifende Themen", topic:"Pflichtenheft", 
                sources: [
                    {
                        name:"Anforderung gs1, nukleus AG.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1072", 
                        type:"Document"
                    }
                ], 
                evaluation:{
                    id:"", 
                    name:"Pflichtenheft", 
                    url:"/reports/instances/48cc"
                }, 
                score: 0.3 
            },
            { 
                client_category:"Projektinhalte", type:"Wirkungsaspekt", group:"übergreifende Themen", topic:"AGB", 
                sources: [
                    {
                        name:"AGB_fuer_IKT-Leistungen_Ausgabe_2020_DE_gs1.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1070", 
                        type:"Document"
                    }
                ], 
                evaluation:{
                    id:"", 
                    name:"AGB", 
                    url:"/reports/instances/4aa9"
                }, 
                score: 0.2 
            },
            { 
                client_category:"Projektinhalte", type:"Wirkungsaspekt", group:"übergreifende Themen", topic:"Geheimhaltungsvereinbarung", 
                sources: [
                    {
                        name:"Aktuelle Geheimhaltungsvereinbarung_20200503_ur_v2.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1073", 
                        type:"Document"
                    }

                ], 
                evaluation:{
                    id:"", 
                    name:"Geheimhaltungsvereinbarung", 
                    url:"/reports/instances/cdb7"
                }, 
                score: 0.7 
            },
            { 
                client_category:"Projektinhalte", type:"Wirkungsaspekt", group:"übergreifende Themen", topic:"Vertragsentwurf", 
                sources: [
                    {
                        name:"Pflichtenheft", 
                        url:"/reports/instances/48cc", 
                        type:"Report"
                    }, 
                    {
                        name:"AGB",
                        url:"/reports/instances/4aa9", 
                        type:"Report"
                    }, 
                    {
                        name:"Geheimhaltungsvereinbarung", 
                        url:"/reports/instances/cdb7", 
                        type:"Report"
                    }, 
                    {
                        name:"AGB_fuer_IKT-Leistungen_Ausgabe_2020_DE_gs1.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1070", 
                        type:"Document"
                    }, 
                    {
                        name:"Anforderung gs1, nukleus AG.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1072", 
                        type:"Document"
                    }, 
                    {
                        name:"Aktuelle Geheimhaltungsvereinbarung_20200503_ur_v2.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1073", 
                        type:"Document"
                    }
                ], 
                evaluation:{
                    id:"", 
                    name:"Vertragsentwurf", 
                    url:"/reports/instances/bf51"
                }, 
                score: 0.5 
            },
            { 
                client_category:"Projektinhalte", type:"Wirkungsaspekt", group:"übergreifende Themen", topic:"Vertragsvorlage und finaler Vertrag", 
                sources: [
                    {
                        name:"20240409_Anforderung nukleus AG_9-agreement final_ur.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1074", 
                        type:"Document"
                    },
                    {       
                        name:"Vertrag _nukleus AG_9-agreement final_ur.pdf", 
                        url:"https://dev.nukleus.technology/nextcloud/apps/files/?dir=/GS1-Demo/GS1%20Suchfunktion/Vereinbarung%20und%20Ausgangslage&openfile=1622", 
                        type:"Document"
                    },
                    {
                        name:"Vertragsentwurf", 
                        url: "/reports/instances/bf51", 
                        type:"Report"
                    },
                ], 
                evaluation:{
                    id:"", 
                    name:"Vertragsvorlage und finaler Vertrag", 
                    url:"/reports/instances/c00f"
                }, 
                score: 0.5 
            }
        ]         
    }
}

export default config;